import AuthStateProvider from '../components/authStateProvider';
import ChoosePlan from '../components/registerComps/register';
import Footer from '../components/footer';
import Header from '../components/header/header';
import React from 'react';
import SEO from '../components/SEO';
// import ChoosePlan from '../components/registerComps/choosePlan';

const RegisterPage = (props) => {
  return (
    <AuthStateProvider authRequired={false} className="h-100 bg-grey" {...props}>
      <SEO title="Subscripton Levels" />
      <Header hideMiddle={true} show={true} />
      <ChoosePlan path="/" {...props} />
      <Footer />
    </AuthStateProvider>
  );
};

export default RegisterPage;
